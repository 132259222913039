// Here you can add other styles
body {
  font-family: 'Noto Sans CJK KR', Noto Sans KR, sans-serif;
}
.c-app {
  color: #333;
}
.form-control:focus {
  outline: none;
  box-shadow: none;
}
.grid {
  display: grid;
}
.no-round {
  border-radius: 0;
}
.image-placeholder {
  background-color: #eee;
}
.btn:focus,
.btn.focus {
  box-shadow: none;
}

.c-sidebar-nav-title {
  font-size: 14px;
}

.c-sidebar-nav-dropdown.c-show {
  > .c-sidebar-nav-dropdown-toggle {
    background-color: #bbb !important;
  }
}
.c-sidebar-nav-dropdown-items {
  > .c-sidebar-nav-item {
    // margin-left: 15px;
  }
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff !important;
  background: #333;
}

a {
  color: inherit;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.background__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.comics__card-container {
  margin: 8px;
  width: 160px;

  .thumbnail {
    width: 142px;
    height: 200px;
  }

  .title {
    margin: 8px 0px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
  }
}
.comic__search__form {
  width: 100%;
}

.comic__search__form-cover {
  position: absolute;
  width: 48px;
  height: 48px;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}

.comic__search__form-title {
  padding-left: 56px;
  font-size: 16px;
  line-height: 48px;
}

.author__search__form-title {
  padding-left: 10px;
  font-size: 16px;
}

.comic__search__form-button {
  line-height: 48px;
  vertical-align: middle;
}

.comic__search__form-badge {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.align-center {
  align-items: center !important;
}

.rug .rug-items.__list {
  min-height: 0px !important;
  padding-top: 0px !important;
}
.uploaded-image__container {
  position: relative;
  img {
    width: 200px;
  }
  button {
    position: absolute;
    right: 0px;
    top: 0px;
    // z-index: 2
  }
  // &:hover:after {
  //   content: '';
  //   background-color: rgba(0, 0, 0, 0.3);
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   width: 100%;
  //   height: 100%;
  //   // z-index: 1;
  // } FIXME - 버튼의 z-index가 다른 dom요소를 뚫고 나오는 문제때문에 임시 비활성화
}
.episode-contents-image__container {
  img {
    width: 120px;
  }
}
.comic-item-card__container {
  border: 1px solid #ddd;
  display: flex;
  position: relative;
  // .comic-item-card__badges {
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  // }
  .comic-item-card__thumbnail {
    width: 30%;
    background: #eee;

    img {
      width: 100%;
    }
  }
  .comic-item-card__metadata {
    width: 70%;
    padding: 8px;
    padding-right: 0px;
    position: relative;
    > span {
      display: flex;
      align-items: flex-start;
      margin-bottom: 6px;
      h6 {
        margin: 0;
        margin-right: 14px;
      }
      button {
        position: absolute;
        right: 0;
        padding: 0;
      }
    }
  }

  .btn-toolbar,
  .dropdown {
    position: absolute;
    right: 0px;
    bottom: 0px;
    .dropdown-toggle {
      border-radius: 50%;
    }
    .dropdown-toggle:after {
      display: none;
    }
  }
}

.comic-disabled {
  background-color: #eeeeee;
}

.episode-sort_root {
  background-color: #ddd;
}

.episode-sort-card__container {
  position: relative;
  overflow: hidden;
  .episode-sort-card__info_container {
    display: flex;

    .episode-sort-card__order {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      font-weight: bold;
      font-size: 1rem;
      padding-right: 10px;
      min-width: 49px;
      border-radius: 5px 0px 0px 5px;
      background: rgba(220, 230, 255, 0.2);
      @media screen and (max-width: 768px) {
        font-size: 0.5rem;
        min-width: 30px;
      }
    }
    .episode-sort-card__info {
      display: flex;
      width: auto;
      @media screen and (max-width: 585px) {
        flex-direction: column;
      }
      .episode-sort-card__thumbnail {
        display: flex;
        row-gap: 5px;
        min-width: 300px;
        overflow: hidden;
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
          min-width: 200px;
        }
        .episode-sort-card__thumbnail_box {
          // max-width: 200px;
          // width: 100%;
          height: 128px;
          img {
            width: 92px;
            height: 100%;
            background-color: #eee;
            overflow: hidden;
          }
        }
      }
      .comic-item-card__metadata {
        padding: 8px;
        padding-right: 0px;
        position: relative;
        .episode-sort-card__title {
          font-size: 1.1rem;
          font-weight: 500;
          // display: flex;
          // align-items: flex-start;
          margin-bottom: 6px;

          button {
            position: absolute;
            right: 0;
            padding: 0;
          }
        }
        & > label {
          @media screen and (max-width: 768px) {
            font-size: 0.7rem;
          }
        }
        .episode-sort-card__paytext {
          color: #fcc439;
          @media screen and (max-width: 768px) {
            font-size: 10px;
          }
        }
        .episode-sort-card__paytext_discount {
          color: #bbb;
          text-decoration: line-through;
          margin-right: 5px;
          font-size: 12px;
          @media screen and (max-width: 768px) {
            font-size: 10px;
          }
        }
        .episode-sort-card__paytext_free {
          color: #9365fe;
          @media screen and (max-width: 768px) {
            font-size: 10px;
          }
        }
      }
    }

    .btn-toolbar {
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }
}

.floating-time {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #ccc;
  justify-content: center;
}

.promotion-search-form {
  display: flex;
  justify-content: space-between;
  .selects {
    width: auto;
    margin: 0 20px;
  }
}

.flex-justify-between {
  display: flex;
  justify-content: space-between;
}

.table-4week-background {
  tbody {
    tr {
      &:nth-of-type(-n + 2) {
        background: #eee;
      }
    }
  }
}

.first-row-background {
  tbody {
    tr {
      &:nth-of-type(1) {
        background: #eee;
      }
    }
  }
}

.selected {
  background: #2eb85c;
}

.inline-inputs {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.number-input {
  text-align: right;
}

table td {
  font-family: Roboto;
}

.none-display-row {
  color: #d8dbe0;
  background-color: rgb(170, 170, 170);
  border-color: #d8dbe0;
}

.wait-display-row {
  color: #d8dbe0;
  background-color: #003300;
  border-color: #d8dbe0;
}

.search-header {
  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    > div {
      justify-content: flex-start;
    }
  }
}
.react-datepicker-popper {
  z-index: 5 !important;
}

.coin_return_modal {
  margin-bottom: 10px; /* 아이템 간 간격 추가 */
  margin-right: 0.5rem;
  float: right;
}
